<template>
  <div class="cmdb-attr-filter">
    <esmp-select
      class="no-margin"
      :disabled="disabled"
      :placeholder="selectLabel"
      :value="attrLabel"
      @on-change="setValue($event, 'attrLabel')"
    >
      <esmp-select-option
        v-for="item in selectItems"
        :key="item"
        :value="item"
      >
        {{ item }}
      </esmp-select-option>
    </esmp-select>
    <esmp-input
      class="ml-10"
      :disabled="disabled"
      :label="inputLabel"
      :value="attrValue"
      @input="setValue($event, 'attrValue')"
    />
  </div>
</template>

<script>
export default {
  name: 'CmdbAttrFilter',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectLabel: {
      type: String,
      default: 'Атрибут',
    },
    inputLabel: {
      type: String,
      default: 'Значение атрибута',
    },
    selectItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      attrLabel: null,
      attrValue: null,
    };
  },
  methods: {
    checkDefaultValue() {
      if (this.value.length) {
        this.attrLabel = this.value[0].attrLabel;
        this.attrValue = this.value[0].attrValue;
      }
    },
    setValue(value, param) {
      this[param] = value;

      if (this.attrLabel && this.attrValue) {
        this.$emit('change', {
          attrLabel: this.attrLabel,
          attrValue: this.attrValue,
        });
      }
    },
  },
  mounted() {
    this.checkDefaultValue();
  },
};
</script>

<style lang="scss" scoped>
.cmdb-attr-filter {
  display: flex;

  .esmp-input {
    width: 50%;
  }
}
</style>
